import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { firestore } from '../firebase';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { useTheme } from '../ThemeContext';

const BulkImportPage = () => {
  const { projectId } = useParams();
  const [taskText, setTaskText] = useState('');
  const [taskPreview, setTaskPreview] = useState([]);
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  // Load taskText from localStorage on mount
  useEffect(() => {
    const savedText = localStorage.getItem('bulkImportTaskText');
    if (savedText) {
      setTaskText(savedText);
      setTaskPreview(parseTasks(savedText));
    }

    // Prompt before closing the tab
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ''; // Display a default warning message
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Save taskText to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('bulkImportTaskText', taskText);
  }, [taskText]);

  const handleBack = () => {
    navigate(`/projects/${projectId}/tasks`);
  };

  const parseTasks = (text) => {
    const lines = text.split('\n');
    const tasks = [];
    let currentTask = null;

    lines.forEach((line) => {
      if (line.trim() === '') return;
      
      // Process notes, tabbed lines, or regular lines
      const trimmedLine = line.trim();
      if (trimmedLine.startsWith('>!') || trimmedLine.startsWith('!>')) {
        // This is an important note
        if (currentTask) {
          tasks.push(currentTask);
        }
        currentTask = { 
          title: trimmedLine.substring(2).trim(), 
          description: '',
          isNote: true,
          isImportant: true
        };
      } else if (trimmedLine.startsWith('>')) {
        // This is a regular note
        if (currentTask) {
          tasks.push(currentTask);
        }
        currentTask = { 
          title: trimmedLine.substring(1).trim(), 
          description: '',
          isNote: true,
          isImportant: false
        };
      } else if (trimmedLine.startsWith('!')) {
        // This is an important task
        if (currentTask) {
          tasks.push(currentTask);
        }
        currentTask = { 
          title: trimmedLine.substring(1).trim(), 
          description: '',
          isNote: false,
          isImportant: true
        };
      } else if (!line.startsWith('\t')) {
        // This is a regular task
        if (currentTask) {
          tasks.push(currentTask);
        }
        currentTask = { 
          title: trimmedLine, 
          description: '',
          isNote: false,
          isImportant: false
        };
      } else if (currentTask) {
        // Handle tabbed lines as description
        let trimmedLine = line.trim();
        if (currentTask.description) {
          // If there's already a description, add a line break
          currentTask.description += '\n' + trimmedLine;
        } else {
          currentTask.description = trimmedLine;
        }
      }
    });

    if (currentTask) {
      tasks.push(currentTask);
    }

    return tasks;
  };

  const handleTaskTextChange = (e) => {
    const text = e.target.value;
    setTaskText(text);
    setTaskPreview(parseTasks(text));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const { selectionStart, selectionEnd } = e.target;
      const value = taskText;
      const newValue = value.substring(0, selectionStart) + '\t' + value.substring(selectionEnd);
      setTaskText(newValue);
      setTimeout(() => {
        e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
      }, 0);
    }
  };

  const bulkImportTasks = async () => {
    if (!projectId) {
      console.error("Error: projectId is not defined.");
      return;
    }

    const tasks = parseTasks(taskText);
    const projectRef = doc(firestore, 'projects', projectId);

    const formattedTasks = tasks.map((task, index) => ({
      title: task.title,
      description: task.description || '',
      completed: false,
      id: new Date().getTime() + index,
      priority: task.isImportant ? 'High' : 'Low',
      isNote: task.isNote || false,
      isImportant: task.isImportant || false,
    }));

    try {
      await updateDoc(projectRef, {
        tasks: arrayUnion(...formattedTasks),
      });
      setTaskText('');
      setTaskPreview([]);
      localStorage.removeItem('bulkImportTaskText');
    } catch (error) {
      console.error('Error importing tasks:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold dark:text-white">Bulk Import Tasks</h1>
        <button
          onClick={handleBack}
          className="bg-gray-500 dark:bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-600 dark:hover:bg-gray-700 flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
          </svg>
          Back
        </button>
      </div>
      
      <div className="bg-blue-50 dark:bg-blue-900 border-l-4 border-blue-500 p-4 mb-6">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-500 dark:text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-blue-800 dark:text-blue-300">Formatting Tips:</h3>
            <div className="mt-2 text-sm text-blue-700 dark:text-blue-400">
              <ul className="list-disc pl-5 space-y-1">
                <li>Each new line creates a new task</li>
                <li>Start a line with <code>!</code> to mark it as important (high priority)</li>
                <li>Start a line with <code>&gt;</code> to create a note instead of a task</li>
                <li>Start a line with <code>&gt;!</code> or <code>!&gt;</code> to create an important note</li>
                <li>Start a line with a TAB to add it as a description to the previous task</li>
                <li>Use TAB key to insert tabs (press TAB while typing)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <textarea
            value={taskText}
            onChange={handleTaskTextChange}
            onKeyDown={handleKeyDown}
            rows="15"
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            placeholder="Enter tasks. Each new line without tabs will be a task. Start with ! for important tasks. Start with > for a note. Start with >! or !> for an important note. Lines starting with tabs will be the description."
          />
          <button
            onClick={bulkImportTasks}
            className="bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-md mt-4 hover:bg-blue-600 dark:hover:bg-blue-700"
          >
            Import Tasks
          </button>
        </div>

        <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-md border border-gray-300 dark:border-gray-700">
          <h2 className="text-xl font-bold mb-4 dark:text-white">Task Preview</h2>
          {taskPreview.length > 0 ? (
            <ul className="space-y-4">
              {taskPreview.map((task, index) => (
                <li key={index} className="border-b dark:border-gray-700 pb-2">
                  <h3 className="font-semibold dark:text-white">
                    {task.isNote ? (
                      task.isImportant ? (
                        <span className="text-red-500 dark:text-red-400">⚠️ 📝 {task.title}</span>
                      ) : (
                        <span className="text-blue-500 dark:text-blue-400">📝 {task.title}</span>
                      )
                    ) : task.isImportant ? (
                      <span className="text-red-500 dark:text-red-400">⚠️ {task.title}</span>
                    ) : (
                      task.title
                    )}
                  </h3>
                  {task.description && (
                    <div className="text-sm text-gray-600 dark:text-gray-400 whitespace-pre-wrap">
                      {task.description}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500 dark:text-gray-400">No tasks to preview</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BulkImportPage;
