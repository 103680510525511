import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import { ThemeProvider } from './ThemeContext';
import LayouMain from './components/layouts/LayoutMain';
import ProfilePage from './pages/ProfilePage';
import HomePage from './pages/HomePage';
import ProjectPage from './pages/ProjectPage';
import TaskPage from './pages/TaskPage';
import BulkImportPage from './pages/BulkImportPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <LayouMain>
            <Routes>

            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/projects/:projectId/tasks" element={<TaskPage />} />
            <Route path="/bulk-import/:projectId" element={<BulkImportPage />} />
            <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route for 404 */}
            <Route path="/projects" element={<ProjectPage />} />

          </Routes>
          </LayouMain>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
