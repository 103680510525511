import React from 'react';
import { useTheme } from '../ThemeContext';

const Footer = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <footer className="bg-gray-800 dark:bg-gray-900 text-white py-3 px-2 md:p-4 transition-colors duration-200">
      <div className="container mx-auto text-center">
        <p className="text-sm md:text-base">&copy; 2024 TaskUno. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
