import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuth } from '../AuthContext';
import { useTheme } from '../ThemeContext';
import ThemeToggle from './ThemeToggle';

const NavBar = () => {
  const { currentUser } = useAuth();
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const auth = getAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        navigate('/login');
        setIsMenuOpen(false);
      })
      .catch((error) => {
        console.error('Error during sign out:', error);
      });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-gray-800 dark:bg-gray-900 text-white p-4 shadow-lg relative z-10">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-xl font-bold">
          μTask.app
        </Link>
        
        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center space-x-4">
          <ThemeToggle />
          {currentUser ? (
            <>
              <Link to="/projects" className="hover:underline">
                Projects
              </Link>
              <Link to="/profile" className="hover:underline">
                Profile
              </Link>
              <button
                onClick={handleLogout}
                className="bg-red-500 dark:bg-red-600 px-3 py-2 rounded hover:bg-red-600 dark:hover:bg-red-700"
              >
                Logout
              </button>
            </>
          ) : (
            <Link to="/login" className="bg-blue-500 dark:bg-blue-600 px-3 py-2 rounded hover:bg-blue-600 dark:hover:bg-blue-700">
              Login
            </Link>
          )}
        </div>
        
        {/* Mobile Hamburger Button */}
        <button 
          className="md:hidden text-white focus:outline-none"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isMenuOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            )}
          </svg>
        </button>
      </div>
      
      {/* Mobile Menu Dropdown */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-full left-0 w-full bg-gray-800 dark:bg-gray-900 shadow-lg">
          <div className="flex flex-col p-4 space-y-3">
            <div className="flex items-center py-2">
              <span className="text-sm text-gray-300 mr-3">Theme:</span>
              <ThemeToggle />
            </div>
            {currentUser ? (
              <>
                <Link 
                  to="/projects" 
                  className="block py-2 hover:bg-gray-700 dark:hover:bg-gray-800 px-3 rounded"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Projects
                </Link>
                <Link 
                  to="/profile" 
                  className="block py-2 hover:bg-gray-700 dark:hover:bg-gray-800 px-3 rounded"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Profile
                </Link>
                <button
                  onClick={handleLogout}
                  className="bg-red-500 dark:bg-red-600 px-3 py-2 rounded hover:bg-red-600 dark:hover:bg-red-700 text-left"
                >
                  Logout
                </button>
              </>
            ) : (
              <Link 
                to="/login" 
                className="bg-blue-500 dark:bg-blue-600 px-3 py-2 rounded hover:bg-blue-600 dark:hover:bg-blue-700"
                onClick={() => setIsMenuOpen(false)}
              >
                Login
              </Link>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
