import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { firestore } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useTheme } from '../ThemeContext';

const TaskPage = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({ 
    title: '', 
    description: '', 
    priority: 'Low',
    isNote: false,
    isImportant: false
  });
  const [projectTitle, setNewProjectTitle] = useState();
  const [editingTask, setEditingTask] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [sortOption, setSortOption] = useState('completed-bottom');
  const [filterOptions, setFilterOptions] = useState({
    showCompleted: true,
    showNotes: true,
    showTasks: true,
    showImportant: true,
    priority: 'all'
  });
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const fetchTasks = async () => {
      if (projectId) {
        const projectRef = doc(firestore, 'projects', projectId);
        const projectSnapshot = await getDoc(projectRef);

        if (projectSnapshot.exists()) {
          const projectData = projectSnapshot.data();
          setNewProjectTitle(projectData.title)
          
        
          const sortedTasks = projectData.tasks
            ? [...projectData.tasks].sort((a, b) => {
              if (a.completed === b.completed) {
                return b.id - a.id;
              }
              return a.completed - b.completed;
            })
            : [];
          setTasks(sortedTasks);
        } else {
          console.error('Project does not exist');
        }
      }
    };

    fetchTasks();
  }, [projectId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  const handleAddTask = async (e) => {
    e.preventDefault();

    if (newTask.title.trim()) {
      const updatedTasks = [
        ...tasks,
        {
          ...newTask,
          completed: false,
          id: tasks.length + 1,
        },
      ];

      const projectRef = doc(firestore, 'projects', projectId);
      await updateDoc(projectRef, { tasks: updatedTasks });

      const sortedTasks = updatedTasks.sort((a, b) => {
        if (a.completed === b.completed) {
          return b.id - a.id;
        }
        return a.completed - b.completed;
      });

      setTasks(sortedTasks);
      setNewTask({ title: '', description: '', priority: 'Low', isNote: false, isImportant: false });
      setIsAddModalOpen(false);
    }
  };

  const handleMarkComplete = async (taskId) => {
    const taskToComplete = tasks.find(task => task.id === taskId);
    
    // Don't allow notes to be marked as completed
    if (taskToComplete.isNote) {
      return;
    }
    
    const updatedTasks = tasks.map((task) =>
      task.id === taskId ? { ...task, completed: true } : task
    );

    const projectRef = doc(firestore, 'projects', projectId);
    await updateDoc(projectRef, { tasks: updatedTasks });

    const sortedTasks = updatedTasks.sort((a, b) => {
      if (a.completed === b.completed) {
        return b.id - a.id;
      }
      return a.completed - b.completed;
    });

    setTasks(sortedTasks);
  };


  const handleMarkIncomplete = async (taskId) => {
    const updatedTasks = tasks.map((task) =>
      task.id === taskId ? { ...task, completed: false } : task
    );

    const projectRef = doc(firestore, 'projects', projectId);
    await updateDoc(projectRef, { tasks: updatedTasks });

    const sortedTasks = updatedTasks.sort((a, b) => {
      if (a.completed === b.completed) {
        return b.id - a.id;
      }
      return a.completed - b.completed;
    });

    setTasks(sortedTasks);
  };

  const handleBulkImportClick = () => {
    navigate(`/bulk-import/${projectId}`);
  };
  
  const handleEditClick = (task) => {
    setEditingTask(task);
    setIsEditModalOpen(true);
  };
  
  const handleEditTaskChange = (e) => {
    const { name, value } = e.target;
    setEditingTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };
  
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    
    if (editingTask.title.trim()) {
      const updatedTasks = tasks.map((task) => 
        task.id === editingTask.id ? editingTask : task
      );
      
      const projectRef = doc(firestore, 'projects', projectId);
      await updateDoc(projectRef, { tasks: updatedTasks });
      
      setTasks(updatedTasks);
      setIsEditModalOpen(false);
      setEditingTask(null);
    }
  };

  // Filter and sort tasks based on current options
  const getFilteredAndSortedTasks = () => {
    let filteredTasks = [...tasks];
    
    // Apply filters
    if (!filterOptions.showCompleted) {
      filteredTasks = filteredTasks.filter(task => !task.completed);
    }
    
    if (!filterOptions.showNotes) {
      filteredTasks = filteredTasks.filter(task => !task.isNote);
    }
    
    if (!filterOptions.showTasks) {
      filteredTasks = filteredTasks.filter(task => task.isNote);
    }
    
    if (!filterOptions.showImportant) {
      filteredTasks = filteredTasks.filter(task => !task.isImportant);
    }
    
    if (filterOptions.priority !== 'all') {
      filteredTasks = filteredTasks.filter(task => task.priority === filterOptions.priority);
    }
    
    // Apply sorting
    switch (sortOption) {
      case 'newest':
        filteredTasks.sort((a, b) => b.id - a.id);
        break;
      case 'oldest':
        filteredTasks.sort((a, b) => a.id - b.id);
        break;
      case 'priority-high':
        filteredTasks.sort((a, b) => {
          const priorityOrder = { 'High': 3, 'Medium': 2, 'Low': 1 };
          return priorityOrder[b.priority] - priorityOrder[a.priority];
        });
        break;
      case 'priority-low':
        filteredTasks.sort((a, b) => {
          const priorityOrder = { 'High': 3, 'Medium': 2, 'Low': 1 };
          return priorityOrder[a.priority] - priorityOrder[b.priority];
        });
        break;
      case 'alphabetical':
        filteredTasks.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'completed':
        filteredTasks.sort((a, b) => {
          if (a.completed === b.completed) {
            return b.id - a.id;
          }
          return a.completed - b.completed;
        });
        break;
      case 'completed-bottom':
        filteredTasks.sort((a, b) => {
          if (a.completed === b.completed) {
            return b.id - a.id;
          }
          return a.completed - b.completed;
        });
        break;
      default:
        break;
    }
    
    return filteredTasks;
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4 dark:text-white">
        <button 
          onClick={() => navigate('/projects')} 
          className="text-blue-500 dark:text-blue-400 mr-2 hover:text-blue-600 dark:hover:text-blue-300 transition-colors"
          aria-label="Back to projects"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </button>
        Tasks | {projectTitle} | <span className='text-red-600 dark:text-red-400'>
          {tasks.filter(task => !task.completed).length}
          </span>
          /{tasks?.length || 0}
      </h1>

      <div className="flex space-x-2 mb-6">
        <button
          onClick={() => setIsAddModalOpen(true)}
          className="bg-blue-500 dark:bg-blue-600 text-white p-2 rounded-md hover:bg-blue-600 dark:hover:bg-blue-700"
        >
          Add New Task
        </button>
        
        <button
          onClick={handleBulkImportClick}
          className="bg-green-500 dark:bg-green-600 text-white p-2 rounded-md hover:bg-green-600 dark:hover:bg-green-700"
        >
          Bulk Import Tasks
        </button>
      </div>

      {/* Sort and Filter Controls */}
      <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4 mb-6 border border-gray-200 dark:border-gray-700">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div className="flex flex-col sm:flex-row gap-4">
            <div>
              <label htmlFor="sort-option" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Sort By
              </label>
              <select
                id="sort-option"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                className="border border-gray-300 dark:border-gray-600 p-2 rounded-md w-full bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              >
                <option value="completed-bottom">Completed at Bottom (Default)</option>
                <option value="newest">Newest First</option>
                <option value="oldest">Oldest First</option>
                <option value="priority-high">Priority (High to Low)</option>
                <option value="priority-low">Priority (Low to High)</option>
                <option value="alphabetical">Alphabetical</option>
                <option value="completed">Completion Status</option>
              </select>
            </div>
            
            <div>
              <label htmlFor="priority-filter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Priority
              </label>
              <select
                id="priority-filter"
                value={filterOptions.priority}
                onChange={(e) => setFilterOptions({...filterOptions, priority: e.target.value})}
                className="border border-gray-300 dark:border-gray-600 p-2 rounded-md w-full bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              >
                <option value="all">All Priorities</option>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>
          </div>
          
          <div className="flex flex-wrap gap-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={filterOptions.showCompleted}
                onChange={(e) => setFilterOptions({...filterOptions, showCompleted: e.target.checked})}
                className="form-checkbox h-5 w-5 text-blue-500 dark:text-blue-400"
              />
              <span className="ml-2 text-gray-700 dark:text-gray-300">Show Completed</span>
            </label>
            
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={filterOptions.showNotes}
                onChange={(e) => setFilterOptions({...filterOptions, showNotes: e.target.checked})}
                className="form-checkbox h-5 w-5 text-yellow-500 dark:text-yellow-400"
              />
              <span className="ml-2 text-gray-700 dark:text-gray-300">Show Notes</span>
            </label>
            
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={filterOptions.showTasks}
                onChange={(e) => setFilterOptions({...filterOptions, showTasks: e.target.checked})}
                className="form-checkbox h-5 w-5 text-blue-500 dark:text-blue-400"
              />
              <span className="ml-2 text-gray-700 dark:text-gray-300">Show Tasks</span>
            </label>
            
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={filterOptions.showImportant}
                onChange={(e) => setFilterOptions({...filterOptions, showImportant: e.target.checked})}
                className="form-checkbox h-5 w-5 text-red-500 dark:text-red-400"
              />
              <span className="ml-2 text-gray-700 dark:text-gray-300">Show Important</span>
            </label>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {getFilteredAndSortedTasks().length > 0 ? (
          getFilteredAndSortedTasks().map((task) => (
            <div
              key={task.id}
              className={`shadow-md p-4 rounded-lg border ${
                task.completed 
                  ? 'border-green-500 bg-green-50 dark:bg-green-900 dark:border-green-700' 
                  : task.isNote
                    ? task.isImportant
                      ? 'border-red-500 border-2 bg-note-light dark:bg-note-dark dark:border-red-700'
                      : 'border-yellow-500 bg-note-light dark:bg-note-dark dark:border-yellow-700'
                    : task.isImportant
                      ? 'border-red-500 border-2 bg-white dark:bg-gray-800 dark:border-red-700'
                      : 'border-gray-200 bg-white dark:bg-gray-800 dark:border-gray-700'
                }`}
            >
              <h3 className="text-lg font-semibold dark:text-white">
                {task.isNote ? (
                  task.isImportant ? (
                    <span className="text-red-500 dark:text-red-400">⚠️ 📝 {task.title}</span>
                  ) : (
                    <span className="text-yellow-600 dark:text-gray-200">📝 {task.title}</span>
                  )
                ) : task.isImportant ? (
                  <span className="text-red-500 dark:text-red-400">⚠️ {task.title}</span>
                ) : (
                  task.title
                )}
              </h3>
              {task.description && (
                <div className="my-2 text-gray-700 dark:text-gray-300 italic whitespace-pre-wrap">
                  {task.description}
                </div>
              )}
              <div className="flex flex-wrap justify-between items-center text-sm text-gray-600 dark:text-gray-400 mt-2">
                <div className="flex items-center space-x-2">
                  <span className="whitespace-nowrap">Priority: {task.priority}</span>
                  <span>|</span>
                  <span className="whitespace-nowrap">Completed: {task.completed ? 'Yes' : 'No'}</span>
                  {task.isNote && <span>|</span>}
                  {task.isNote && <span className="whitespace-nowrap">Note</span>}
                </div>
                <div className="flex mt-2 sm:mt-0 space-x-2">
                  <button
                    onClick={() => handleEditClick(task)}
                    className="text-blue-500 dark:text-blue-400 px-2 py-1 rounded-md hover:bg-blue-100 dark:hover:bg-blue-900 flex items-center"
                    title="Edit task"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                    Edit
                  </button>
                  {!task.completed && !task.isNote ? (
                    <button
                      onClick={() => handleMarkComplete(task.id)}
                      className="text-green-500 dark:text-green-400 px-2 py-1 rounded-md hover:bg-green-100 dark:hover:bg-green-900 flex items-center"
                      title="Mark as complete"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      Complete
                    </button>
                  ) : task.completed && !task.isNote ? (
                    <button
                      onClick={() => handleMarkIncomplete(task.id)}
                      className="text-orange-500 dark:text-orange-400 px-2 py-1 rounded-md hover:bg-orange-100 dark:hover:bg-orange-900 flex items-center"
                      title="Mark as incomplete"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                      Undo
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 dark:text-gray-400">No tasks found for this project.</p>
        )}
      </div>
      
      {/* Add Task Modal */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-md max-h-[90vh] overflow-y-auto">
            <div className="p-5">
              <h3 className="text-xl font-semibold mb-4 dark:text-white">Add New Task</h3>
              <form onSubmit={handleAddTask} className="space-y-4">
                <div>
                  <label htmlFor="new-title" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Title
                  </label>
                  <input
                    id="new-title"
                    type="text"
                    name="title"
                    value={newTask.title}
                    onChange={handleInputChange}
                    placeholder="Task Title"
                    required
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  />
                </div>
                
                <div>
                  <label htmlFor="new-description" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description (optional)
                  </label>
                  <textarea
                    id="new-description"
                    name="description"
                    value={newTask.description}
                    onChange={handleInputChange}
                    placeholder="Task Description"
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md h-24 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  />
                </div>
                
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="new-priority" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Priority
                    </label>
                    <select
                      id="new-priority"
                      name="priority"
                      value={newTask.priority}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    >
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option value="High">High</option>
                    </select>
                  </div>
                  
                  <div>
                    <label htmlFor="new-type" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type
                    </label>
                    <select
                      id="new-type"
                      name="isNote"
                      value={newTask.isNote ? "true" : "false"}
                      onChange={(e) => {
                        setNewTask({
                          ...newTask,
                          isNote: e.target.value === "true"
                        });
                      }}
                      className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    >
                      <option value="false">Task</option>
                      <option value="true">Note</option>
                    </select>
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Status
                  </label>
                  <div className="flex items-center space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        name="isImportant"
                        checked={newTask.isImportant || false}
                        onChange={(e) => {
                          setNewTask({
                            ...newTask,
                            isImportant: e.target.checked
                          });
                        }}
                        className="form-checkbox h-5 w-5 text-red-500 dark:text-red-400"
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">Important</span>
                    </label>
                  </div>
                </div>
                
                <div className="flex flex-col sm:flex-row gap-2 pt-2">
                  <button
                    type="submit"
                    className="bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-md sm:flex-1 hover:bg-blue-600 dark:hover:bg-blue-700 transition"
                  >
                    Add Task
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsAddModalOpen(false);
                      setNewTask({ title: '', description: '', priority: 'Low', isNote: false, isImportant: false });
                    }}
                    className="bg-gray-500 dark:bg-gray-600 text-white px-4 py-2 rounded-md sm:flex-1 hover:bg-gray-600 dark:hover:bg-gray-700 transition"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      
      {/* Edit Task Modal */}
      {isEditModalOpen && editingTask && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-md max-h-[90vh] overflow-y-auto">
            <div className="p-5">
              <h3 className="text-xl font-semibold mb-4 dark:text-white">Edit Task</h3>
              <form onSubmit={handleEditSubmit} className="space-y-4">
                <div>
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Title
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    value={editingTask.title}
                    onChange={handleEditTaskChange}
                    required
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    placeholder="Task title"
                  />
                </div>
                
                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description (optional)
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={editingTask.description || ''}
                    onChange={handleEditTaskChange}
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md h-24 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    placeholder="Task description"
                  />
                </div>
                
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="priority" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Priority
                    </label>
                    <select
                      id="priority"
                      name="priority"
                      value={editingTask.priority}
                      onChange={handleEditTaskChange}
                      className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    >
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option value="High">High</option>
                    </select>
                  </div>
                  
                  <div>
                    <label htmlFor="isNote" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Type
                    </label>
                    <select
                      id="isNote"
                      name="isNote"
                      value={editingTask.isNote ? "true" : "false"}
                      onChange={(e) => {
                        setEditingTask({
                          ...editingTask,
                          isNote: e.target.value === "true"
                        });
                      }}
                      className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    >
                      <option value="false">Task</option>
                      <option value="true">Note</option>
                    </select>
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Status
                  </label>
                  <div className="flex items-center space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        name="isImportant"
                        checked={editingTask.isImportant || false}
                        onChange={(e) => {
                          setEditingTask({
                            ...editingTask,
                            isImportant: e.target.checked
                          });
                        }}
                        className="form-checkbox h-5 w-5 text-red-500 dark:text-red-400"
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">Important</span>
                    </label>
                  </div>
                </div>
                
                <div className="flex flex-col sm:flex-row gap-2 pt-2">
                  <button
                    type="submit"
                    className="bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-md sm:flex-1 hover:bg-blue-600 dark:hover:bg-blue-700 transition"
                  >
                    Save Changes
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsEditModalOpen(false);
                      setEditingTask(null);
                    }}
                    className="bg-gray-500 dark:bg-gray-600 text-white px-4 py-2 rounded-md sm:flex-1 hover:bg-gray-600 dark:hover:bg-gray-700 transition"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskPage;
