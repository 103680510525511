import React from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';
import { useTheme } from '../../ThemeContext';

const LayoutMain = ({ children }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className={`min-h-screen flex flex-col bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors duration-200`}>
      <NavBar />
      <main className="flex-grow container mx-auto px-2 py-2 md:p-4 overflow-x-hidden">{children}</main>
      <Footer />
    </div>
  );
};

export default LayoutMain;
