import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../firebase';
import { collection, getDocs, query, where, addDoc, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../AuthContext';

const ProjectPage = () => {
  const [projects, setProjects] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [newProjectTitle, setNewProjectTitle] = useState('');
  const [newProjectDescription, setNewProjectDescription] = useState('');
  const [editingProject, setEditingProject] = useState(null);
  const [showNewProjectForm, setShowNewProjectForm] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (currentUser) {
        const userId = currentUser.uid;
        const orgCollection = collection(firestore, 'organizations');
        const orgQuery = query(orgCollection, where('owner', '==', userId));
        const orgSnapshot = await getDocs(orgQuery);
        const orgList = orgSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setOrganizations(orgList);
        
        // Load cached organization and select it, or select the first one if available
        const cachedOrg = localStorage.getItem('selectedOrganization');
        if (cachedOrg && orgList.some(org => org.name === cachedOrg)) {
          setSelectedOrg(cachedOrg);
        } else if (orgList.length > 0) {
          setSelectedOrg(orgList[0].name);
        }
      }
    };

    fetchOrganizations();
  }, [currentUser]);
  
  // Save selected organization to localStorage when it changes
  useEffect(() => {
    if (selectedOrg) {
      localStorage.setItem('selectedOrganization', selectedOrg);
    }
  }, [selectedOrg]);

  useEffect(() => {
    const fetchProjects = async () => {
      if (currentUser && selectedOrg) {
        const userId = currentUser.uid;
        const projectCollection = collection(firestore, 'projects');
        const projectQuery = query(
          projectCollection,
          where('owner', '==', userId),
          where('organization', '==', selectedOrg)
        );

        const projectSnapshot = await getDocs(projectQuery);
        const projectList = projectSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProjects(projectList);
      } else {
        setProjects([]);
      }
    };

    fetchProjects();
  }, [currentUser, selectedOrg]);

  const handleProjectClick = (projectId) => {
    navigate(`/projects/${projectId}/tasks`);
  };

  const handleAddProjectClick = () => {
    setShowNewProjectForm(true);
  };

  const handleNewProjectSubmit = async (e) => {
    e.preventDefault();

    if (newProjectTitle.trim() && selectedOrg) {
      const userId = currentUser.uid;
      const projectCollection = collection(firestore, 'projects');

      await addDoc(projectCollection, {
        title: newProjectTitle,
        description: newProjectDescription.trim(),
        owner: userId,
        organization: selectedOrg,
        tasks: []
      });

      setNewProjectTitle('');
      setNewProjectDescription('');
      setShowNewProjectForm(false);
    }
  };
  
  const handleDeleteProject = async (projectId, e) => {
    e.stopPropagation(); // Prevent navigating to the project
    
    if (window.confirm('Are you sure you want to delete this project? This action cannot be undone.')) {
      try {
        // Delete from Firestore
        await deleteDoc(doc(firestore, 'projects', projectId));
        // Update local state
        setProjects((prevProjects) => prevProjects.filter((project) => project.id !== projectId));
      } catch (error) {
        console.error('Error deleting project:', error);
        alert('Failed to delete project. Please try again.');
      }
    }
  };
  
  const handleEditProjectClick = (project, e) => {
    e.stopPropagation(); // Prevent navigating to the project
    setEditingProject(project);
    setNewProjectTitle(project.title);
    setNewProjectDescription(project.description || '');
  };
  
  const handleEditProjectSubmit = async (e) => {
    e.preventDefault();
    
    if (newProjectTitle.trim() && editingProject) {
      try {
        const projectRef = doc(firestore, 'projects', editingProject.id);
        
        // Update in Firestore
        await updateDoc(projectRef, {
          title: newProjectTitle,
          description: newProjectDescription.trim()
        });
        
        // Update local state
        setProjects((prevProjects) => 
          prevProjects.map((project) => 
            project.id === editingProject.id 
              ? { 
                  ...project, 
                  title: newProjectTitle,
                  description: newProjectDescription.trim() 
                }
              : project
          )
        );
        
        setNewProjectTitle('');
        setNewProjectDescription('');
        setEditingProject(null);
      } catch (error) {
        console.error('Error updating project:', error);
        alert('Failed to update project. Please try again.');
      }
    }
  };

  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
      <div className="bg-white dark:bg-gray-800 shadow-md p-4 border-b border-gray-300 dark:border-gray-700">
        <h1 className="text-2xl font-bold dark:text-white">Projects</h1>
      </div>

      {/* Main Content */}
      <div className="flex flex-col md:flex-row flex-1 overflow-hidden">
        {/* Sidebar Toggle for Mobile */}
        <div className="md:hidden bg-gray-300 dark:bg-gray-700 p-2 flex justify-between items-center">
          <h2 className="text-lg font-semibold dark:text-white">Organizations</h2>
          <button 
            onClick={() => {
              const sidebar = document.getElementById('org-sidebar');
              if (sidebar.classList.contains('hidden')) {
                sidebar.classList.remove('hidden');
              } else {
                sidebar.classList.add('hidden');
              }
            }}
            className="p-1 bg-gray-400 dark:bg-gray-600 rounded text-white"
          >
            {selectedOrg || 'Select Org'} 
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block ml-1" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        
        {/* Sidebar */}
        <div id="org-sidebar" className="hidden md:block md:w-1/4 bg-gray-200 dark:bg-gray-800 p-4 border-r border-gray-300 dark:border-gray-700 overflow-y-auto">
          <h2 className="text-lg font-bold mb-4 hidden md:block dark:text-white">Organizations</h2>
          <ul>
            {organizations.map((org) => (
              <li
                key={org.id}
                onClick={() => {
                  setSelectedOrg(org.name);
                  // Hide sidebar on mobile after selection
                  if (window.innerWidth < 768) {
                    document.getElementById('org-sidebar').classList.add('hidden');
                  }
                }}
                className={`cursor-pointer p-2 rounded-md mb-2 ${
                  selectedOrg === org.name 
                    ? 'bg-blue-500 text-white' 
                    : 'hover:bg-gray-300 dark:hover:bg-gray-700 dark:text-gray-200'
                }`}
              >
                {org.name}
              </li>
            ))}
          </ul>
        </div>

        {/* Projects Section */}
        <div className="flex-1 p-2 md:p-4 overflow-y-auto">
          {selectedOrg && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4">
              {projects.length > 0 ? (
                projects.map((project) => (
                  <div
                    key={project.id}
                    onClick={() => handleProjectClick(project.id)}
                    className="bg-white dark:bg-gray-800 shadow-md p-3 md:p-4 rounded-lg border border-gray-200 dark:border-gray-700 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition relative"
                  >
                    <h3 className="text-base md:text-lg font-semibold pr-16 dark:text-white">{project.title}</h3>
                    {project.description && (
                      <p className="text-xs md:text-sm text-gray-600 dark:text-gray-300 mt-1 mb-2 line-clamp-2">{project.description}</p>
                    )}
                    <p className="text-xs md:text-sm text-gray-600 dark:text-gray-400">
                      <span className="md:ml-4 text-red-600 dark:text-red-400">{project.tasks.filter(task => !task.completed).length}/</span>
                      {project.tasks?.length || 0} {project.tasks?.length === 1 ? 'Task' : 'Tasks'}
                    </p>
                    <div className="absolute top-2 right-2 flex">
                      <button 
                        className="p-1 text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 hover:bg-blue-100 dark:hover:bg-blue-900 rounded-full mr-1"
                        onClick={(e) => handleEditProjectClick(project, e)}
                        title="Edit project"
                        aria-label="Edit project"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                      </button>
                      <button 
                        className="p-1 text-gray-500 dark:text-gray-400 hover:text-red-500 dark:hover:text-red-400 hover:bg-red-100 dark:hover:bg-red-900 rounded-full"
                        onClick={(e) => handleDeleteProject(project.id, e)}
                        title="Delete project"
                        aria-label="Delete project"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500 dark:text-gray-400">No projects found for the selected organization.</p>
              )}

              {/* New Project Card */}
              <div
                onClick={handleAddProjectClick}
                className="bg-green-50 dark:bg-green-900 shadow-md p-3 md:p-4 rounded-lg border border-green-500 dark:border-green-700 flex items-center justify-center cursor-pointer hover:bg-green-200 dark:hover:bg-green-800 transition"
              >
                <span className="text-4xl md:text-5xl text-gray-500 dark:text-gray-300">+</span>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Project Form Modal (New or Edit) */}
      {(showNewProjectForm || editingProject) && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4 z-50">
          <div className="bg-white dark:bg-gray-800 p-4 md:p-6 rounded-md shadow-md w-full max-w-md max-h-[90vh] overflow-y-auto">
            <h2 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 dark:text-white">
              {editingProject ? 'Edit Project' : 'Add New Project'}
            </h2>
            <form onSubmit={editingProject ? handleEditProjectSubmit : handleNewProjectSubmit} className="space-y-3 md:space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Project Title
                </label>
                <input
                  type="text"
                  value={newProjectTitle}
                  onChange={(e) => setNewProjectTitle(e.target.value)}
                  placeholder="Project Title"
                  required
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md text-base bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Description (optional)
                </label>
                <textarea
                  value={newProjectDescription}
                  onChange={(e) => setNewProjectDescription(e.target.value)}
                  placeholder="Project Description"
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md h-20 md:h-24 text-base bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                ></textarea>
              </div>
              
              <div className="flex flex-col sm:flex-row gap-2 pt-2">
                <button
                  type="submit"
                  className="bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-md sm:flex-1 hover:bg-blue-600 dark:hover:bg-blue-700 transition text-base"
                >
                  {editingProject ? 'Update Project' : 'Create Project'}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setShowNewProjectForm(false);
                    setEditingProject(null);
                    setNewProjectTitle('');
                    setNewProjectDescription('');
                  }}
                  className="bg-gray-500 dark:bg-gray-600 text-white px-4 py-2 rounded-md sm:flex-1 hover:bg-gray-600 dark:hover:bg-gray-700 transition text-base"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectPage;
